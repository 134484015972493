import React from 'react';
import {
  Accordion,
  Column,
  Container,
  Layout,
  LayoutBlock,
  Hero,
  Title,
  Description,
  Row,
  Section,
  Actions,
} from '@dragonchain-dev/dragon-kit';
import { Layout as PageLayout, SEO, TypeForm, FeatureRow } from '../../components/_index';

import Archive from '../../assets/images/icons/archive.svg';
import Brain from '../../assets/images/icons/brain-thought.svg';
import Eye from '../../assets/images/icons/eye.svg';
import Touch from '../../assets/images/icons/touch.svg';
import CardSecure from '../../assets/images/icons/card-secure.svg';
import Shield from '../../assets/images/icons/shield.svg';

const EmergencyManagementSystem = () => {
  return (
    <PageLayout>
      <SEO
        title="Case Study: Emergency Management System for Snohomish County, Washington."
        description="A blockchain-based Emergency Management System for the distribution and traceability of medical supplies in Snohomish County, Washington."
      />
      <Layout dark background="blue">
        <Hero
          type="code"
          backgroundImage="https://images.dragonchain.com/texture/clouds-blue@2x.jpg"
          video={{
            url: 'https://www.youtube.com/watch?v=GQQs2cQkaP4',
            autoPlay: true,
          }}
        >
          <Title color="white">Case Study: Emergency Management System.</Title>
          <Description color="blue-light">
            <p>
              A blockchain-based Emergency Management System for the distribution and traceability of medical
              supplies. How Dragonchain delivered a functional proof of concept (POC) for Snohomish County,
              Washington, in 15 days.
            </p>
            <Actions
              actions={[
                {
                  type: 'link',
                  name: 'See documentation',
                  color: 'blue',
                  hover: 'blue-dark',
                  to:
                    'https://www.snohomishcountywa.gov/DocumentCenter/View/76709/Snohomish-County-Researched-an-Emergency-Management-System-for-Better-Supply-Distribution',
                  blank: true,
                },
              ]}
            />
          </Description>
        </Hero>
      </Layout>
      <LayoutBlock>
        <Container>
          <Row gap="small">
            <Column span={12}>
              <Section title="From Idea to Operational Proof of Concept In 15 Days" />
            </Column>
          </Row>
          <FeatureRow
            features={[
              {
                Icon: Archive,
                title: 'Availability',
                body: 'Accurate information on real-time availability of medical supply inventories',
              },
              {
                Icon: Eye,
                title: 'HIPAA Compliant',
                body: 'Decentralized identity capabilities allow for GDPR and HIPAA compliance',
              },
              {
                Icon: Touch,
                title: 'Provable Tracking',
                body:
                  'Test kits, ventilators, generators, usage rates and other metrics are all provably tracked',
              },
              {
                Icon: CardSecure,
                title: 'Priority Distribution',
                body: 'Based on inventory levels supplies can be distributed based on highest priority',
              },
              {
                Icon: Shield,
                title: 'Adaptable Flexibility',
                body: 'The prototype can adapt to different needs and be used as a health management system',
              },
              {
                Icon: Brain,
                title: 'Proof Reports',
                body:
                  'Securely prove that records are legitimate, accurate, and have not been tampered with.',
              },
            ]}
          />
          <Row gap="small">
            <Column span={12}>
              <Section title="How it started">
                Dragonchain was asked by Snohomish County in Washington to research and develop a prototype
                Emergency Management System (EMS). The functional prototype can potentially be deployed by any
                public or private entity.
              </Section>
            </Column>
          </Row>
          <Row gap="small">
            <Column span={12}>
              <Section title="Brainstorm">
                Prior systems were outdated and lacked flexibility, as seen in many actively used legacy
                systems throughout governments and enterprises. The Emergency Management System was used with
                the flexibility that seperates Dragonchain's blockchain technology from other blockchain
                providers.
              </Section>
            </Column>
          </Row>
          <Row gap="small">
            <Column span={12}>
              <Section subtitle="Flexibility" title="Customize">
                The Emergency Management System can be further developed and altered. It is suitable to serve
                as a Health Management System and integrate with other legacy systems and applications.
                Changes can be made depending on the needs of the public.
              </Section>
            </Column>
          </Row>
        </Container>
      </LayoutBlock>
      <LayoutBlock separator>
        <Container>
          <Row gap="large">
            <Column span={12}>
              <Accordion title="The stats">
                <Accordion.Item title="Man-hours">15 days, with 2 developers</Accordion.Item>
                <Accordion.Item title="Snohomish County Deliverables">API access</Accordion.Item>
                <Accordion.Item title="Dragonchain Deliverables">
                  <ul>
                    <li>Blockchain based Emergency Management System</li>
                    <li>API</li>
                    <li>Website</li>
                    <li>Hosting</li>
                  </ul>
                </Accordion.Item>
              </Accordion>
            </Column>
          </Row>
          <Row>
            <Column span={6}>
              <Section title="Want to learn more?">
                Integrate with a future proof Emergency Management System or alter it into other management
                systems, for example for the healthcare industry. Schedule a call with the creators.
              </Section>
            </Column>
            <Column span={6} right middle>
            <Actions
            actions={[
              {
                type: 'link',
                name: 'Contact Us',
                color: 'blue',
                hover: 'blue-dark',
                to: '/contact-us',
              },
            ]}
          />
            </Column>
          </Row>
        </Container>
      </LayoutBlock>
    </PageLayout>
  );
};

export default EmergencyManagementSystem;
